import { Suspense, lazy } from 'react';
import PageErrorBoundary from './PageErrorBoundary';
import Loading from './Loading';

const AppComponent = lazy(() => import('../App'));
const SuspendedAppComponent = (props: any) => (
  <PageErrorBoundary>
    <Suspense fallback={<Loading />}>
      <AppComponent {...props} />
    </Suspense>
  </PageErrorBoundary>
);

export default SuspendedAppComponent;
