import axios, { AxiosInstance } from 'axios';
import { pageLimit } from '../constants/string.constants';

const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers:
    process.env.REACT_APP_ENV === 'local'
      ? {
          Authorization: `Bearer ${process.env.REACT_APP_API_AUTH_TOKEN}`,
        }
      : undefined,
});
export const updateAxiosAuthToken = (token: string) => {
  if (window.localStorage) {
    window.localStorage.setItem('jwt', token);
  }

  axiosInstance.interceptors.request.use(config => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export const registerUser = async (data: any) => {
  const res = await axiosInstance.post(
    `${apiEndPoint}/api/flexio/register`,
    data,
  );
  if (res.status === 500) throw new Error('something went wrong');
  return res.data;
};

export const getShopData = async () => {
  const res = await axiosInstance.get(`${apiEndPoint}/api/shopData/`);
  return res.data;
};

export const printPackingSlip = async (flexioPackageId: string) => {
  // TO DO CHANGE API CALL ENDPOINT
  const res = await axiosInstance.get(
    `${apiEndPoint}/api/fulfillments/packing-slip`,
    {
      params: {
        flexioPackageId,
      },
    },
  );
  return res.data;
};

export const logInUser = async (data: any) => {
  const res = await axiosInstance.post(`${apiEndPoint}/api/flexio/login`, data);
  return res.data;
};

export const resetPassword = async (data: { email: string }) => {
  const res = await axiosInstance.patch(
    `${apiEndPoint}/api/flexio/forgotPassword`,
    data,
  );
  return res.data;
};

export const verifyCode = async (data: { email: string; code: string }) => {
  const res = await axiosInstance.patch(
    `${apiEndPoint}/api/flexio/verifyCode`,
    data,
  );
  return res.data;
};

export type NewPasswordProps = {
  email: string;
  password: string;
  confirmPassword: string;
  code: string;
};
export const setNewPassword = async (data: NewPasswordProps) => {
  const res = await axiosInstance.patch(
    `${apiEndPoint} /api/flexio/newPassword`,
    data,
  );
  return res.data;
};

export const getVerification = async () => {
  const res = await axiosInstance.get(`${apiEndPoint}/api/flexio/verify`);
  return res.data;
};

export const fetchShopSettings = async () => {
  const queryDoc = `query FindStoreSettings {
    findStoreSettings {
      id
      onboardingCompleted
      onboardingStep
      shop
      verifiedManually
    }
  }`;
  const res = await axiosInstance.post(`${apiEndPoint}/graphql`, {
    query: queryDoc,
  });
  return res.data;
};
export const getDashboardData = async (pageNo: number) => {
  // TO DO CHANGE API CALL ENDPOINT
  const queryDoc = `query($take: Int, $skip: Int, $orderBy: [FlexioPoolIdToShopifyFulfillmentIdOrderByWithRelationInput!]) {
    flexioPoolIdToShopifyFulfillmentIds(take: $take, skip: $skip, orderBy: $orderBy) {
      shop
      flexioPackageId
      flexioOrderId
      createdAt
      shopifyOrderAdminGid
      shopifyFulfillmentGid
      id
      shopifyFulfillmentName
      shopifyOrderName
      updatedAt
    }
  }`;
  const res = await axiosInstance.post(`${apiEndPoint}/graphql`, {
    query: queryDoc,
    variables: {
      take: pageLimit + 1,
      skip: (pageNo - 1) * pageLimit,
      orderBy: [
        {
          updatedAt: 'desc',
        },
      ],
    },
  });
  return res.data;
};
export const updateShopSettings = async (variables: any) => {
  const queryDoc = `mutation UpdateStoreSettings($data: SettingsUpdateInput!) {
    updateStoreSettings(data: $data) {
      id
      onboardingCompleted
      onboardingStep
      shop
      verifiedManually
    }
  }`;
  const res = await axiosInstance.post(`${apiEndPoint}/graphql`, {
    query: queryDoc,
    variables,
  });
  return res.data;
};
